import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import 'antd/dist/antd.css';
import 'react-toastify/dist/ReactToastify.css';
import { history} from './_helpers'
import { managerService } from './_services';
import {connect} from 'react-redux'
import { ResetPasswordRequestPage } from './views/ResetPasswordRequestPage';
import { ToastContainer } from 'react-toastify';
import { authenticationActions } from './_actions';


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {

  state={loaded : true}




  render() {

    if(this.state.loaded){
      return (
        <Router history={history}>
            <React.Suspense fallback={loading}>
              <Switch>  
                <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
                <Route exact path='/reset-password' name="Reset Password" component={ResetPasswordRequestPage}/>
                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
              </Switch>
            </React.Suspense>
            <ToastContainer position="bottom-center"/>

        </Router>
      );
    }else{
      return (
        <div style={{width:"99vw",height:"99vh",display:"flex",justifyContent:"center",alignItems:"center"}}>
            ...LOading

        </div>
      )
    }
  
  }
}

const mapState = ( state ) => {
  const { user,loggedIn } = state.authentication
  return  {
    user
  }
}

const actionCreators= {
  logout :authenticationActions.logout
}

export default connect(mapState,actionCreators)(App);
