import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
import { alert } from './alert.reducer';
import { changeState } from './sidebar-reducer'

const rootReducer = combineReducers({
  authentication,
  alert,
  changeState
});

export default rootReducer;