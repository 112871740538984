


import { authenticationConstants } from '../_constants'

const initialState= {loggedIn : false, loggingIn: false, user : {}, token : null,error : null,updating : false,refreshToken : null}

export function authentication(state = initialState, action) {
  switch (action.type) {
    case authenticationConstants.LOGIN_REQUEST:
       return {...state,
        loggingIn : true,
        error : null
    }
    case authenticationConstants.LOGIN_SUCCESS:
       return {...state,
        loggingIn: false,
        loggedIn : true,
        user : action.payload.user,
        error: null
      
    }
    case authenticationConstants.LOGIN_FAILURE : 
        return {
            ...initialState,
            loggingIn :false,
            error : action.error
        }
    case authenticationConstants.LOGOUT : 
    return {
      ...initialState
    }     
    case authenticationConstants.SHARE_ACCESS_REQUEST : 
      return{
        ...state,
        updating: true
      }
    case authenticationConstants.SHARE_ACCESS_SUCCESS : 
    const newUser=state.user
    const sharedExperiencesKeyId = action.sharedExperiences.map(experience => experience.keyId)
    newUser.body.experiences = state.user.body.experiences.map(experience => {
      if(sharedExperiencesKeyId.includes(experience.keyId)){
    
        return{
          ...experience,
          quantity : experience.quantity-1
        }
      }else{
        return experience
      }
    })
    return{
      ...state,
      user: newUser,
      updating:false
    }
    case authenticationConstants.SHARE_ACCESS_FAILURE:
      return {
        ...state,
        updating:false
      }

      case authenticationConstants.SHARE_ACCESS_TO_MANY_REQUEST : 
      return{
        ...state,
        updating: true
      }
    case authenticationConstants.SHARE_ACCESS_TO_MANY_SUCCESS : 
    const newestUser=state.user
    const newSharedExperiencesKeyId = action.payload.sharedExperiences.map(experience => experience.keyId)
    newestUser.body.experiences = state.user.body.experiences.map(experience => {
      if(newSharedExperiencesKeyId.includes(experience.keyId)){
    
        return{
          ...experience,
          quantity : experience.quantity- action.payload.quantity
        }
      }else{
        return experience
      }
    })
    return{
      ...state,
      user: newestUser,
      updating:false
    }
    case authenticationConstants.SHARE_ACCESS_TO_MANY_FAILURE:
      return {
        ...state,
        updating:false
      }

      case authenticationConstants.GET_NEW_TOKEN_REQUEST:
        return{
          ...state,
          updating : true
        }
      case authenticationConstants.GET_NEW_TOKEN_SUCCESS:
        const newUser2 = state.user
        newUser2.token = action.payload.token
        newUser2.refreshToken = action.payload.refreshToken
           return{
          ...state,
          user : newUser2,
          updating:false
        }
      case authenticationConstants.GET_NEW_TOKEN_FAILURE:
        return {
        ...state,
        error : action.error,
        updating: false
        }
    default:
      return state
  }
}