import {authenticationService} from '../_services'
import {authenticationConstants} from '../_constants'
import {managerService} from '../_services'
import { alertActions } from './alert.actions';
import { history } from '../_helpers'
import { toast } from 'react-toastify'

export const authenticationActions = {
    login,
    logout,
    shareAccessToOne,
    shareAccessToMany,
    getNewToken
}


function login(req) {
    return dispatch => {
        dispatch(loginRequest())

       authenticationService.login(req)
            .then(res => { 
                    dispatch(loginSuccess({
                        user : res.data.posted
                  
                }));
                    dispatch(alertActions.success('Login successful'));
                    history.push('/')
                
                })
            .catch(err=> {
                dispatch(alertActions.error('Error while logging in'));

            })
            
           
    };
    
    function loginRequest() { return { type: authenticationConstants.LOGIN_REQUEST } }
    function loginSuccess(payload) { return { type: authenticationConstants.LOGIN_SUCCESS, payload } }
    function loginFailure(error) { return { type: authenticationConstants.LOGIN_FAILURE, error } }

}


function logout() {
    return dispatch => {
        dispatch(logoutAction())
        history.push('/login')
           
    };
    
    function logoutAction() { return { type: authenticationConstants.LOGOUT } }
   

}




function shareAccessToOne(req) {
    return dispatch => {
        dispatch(shareAccessRequest())

        managerService.shareAccessToOne(req)
            .then(res => { 
                    dispatch(shareAccessSuccess(req.student.experiences))
                    toast('Accés Autorisé')
                    dispatch(alertActions.success('shareAccess successful'));
                
                })
            .catch(err=> {
                dispatch(alertActions.error('Error while logging in'));
                toast('Accés Non Autorisé')
                dispatch(shareAccessFailure())
            })
            
           
    };
    
    function shareAccessRequest() { return { type: authenticationConstants.SHARE_ACCESS_REQUEST } }
    function shareAccessSuccess(sharedExperiences) { return { type: authenticationConstants.SHARE_ACCESS_SUCCESS, sharedExperiences } }
    function shareAccessFailure(error) { return { type: authenticationConstants.SHARE_ACCESS_FAILURE, error } }

}



function shareAccessToMany(req) {
    return dispatch => {
        dispatch(shareAccessRequest())

        managerService.shareAccessToMany(req)
            .then(res => { 
                    dispatch(shareAccessSuccess({ sharedExperiences :req.student.experiences,
                    quantity : req.student.id.length}))
                    toast('Accés Autorisé')
                    dispatch(alertActions.success('shareAccess successful'));
                
                })
            .catch(err=> {
                dispatch(alertActions.error('Error while logging in'));
                toast('Accés Non Autorisé')
                dispatch(shareAccessFailure())
            })
            
           
    };
    
    function shareAccessRequest() { return { type: authenticationConstants.SHARE_ACCESS_TO_MANY_REQUEST } }
    function shareAccessSuccess(payload) { return { type: authenticationConstants.SHARE_ACCESS_TO_MANY_SUCCESS, payload } }
    function shareAccessFailure(error) { return { type: authenticationConstants.SHARE_ACCESS_TO_MANY_FAILURE, error } }

}


function getNewToken(req){
    return dispatch =>{
        dispatch(request(req))

        authenticationService.getNewToken(req)
            .then(res=>{  
                    const payload = res.data
                    dispatch(success(payload))
            })
            .catch(
                error =>{

                    dispatch(failure(error))
                    dispatch(alertActions.error(error))
                }
            )
    }

    function request(req) { return {type : authenticationConstants.GET_NEW_TOKEN_REQUEST,req}}
    function success(payload) { return {type : authenticationConstants.GET_NEW_TOKEN_SUCCESS,payload}}
    function failure(error) { return {type : authenticationConstants.GET_NEW_TOKEN_FAILURE,error}}
}
