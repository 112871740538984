export const authenticationConstants ={
    LOGIN_REQUEST : "USER_LOGIN_REQUEST",
    LOGIN_SUCCESS : "USER_LOGIN_SUCCESS",
    LOGIN_FAILURE : "USER_LOGIN_FAILURE",

    SHARE_ACCESS_REQUEST : "USER_SHARE_ACCESS_REQUEST",
    SHARE_ACCESS_SUCCESS : "USER_SHARE_ACCESS_SUCCESS",
    SHARE_ACCESS_FAILURE : "USER_SHARE_ACCESS_FAILURE",

    SHARE_ACCESS_TO_MANY_REQUEST : "USER_SHARE_ACCESS_TO_MANY_REQUEST",
    SHARE_ACCESS_TO_MANY_SUCCESS : "USER_SHARE_ACCESS_TO_MANY_SUCCESS",
    SHARE_ACCESS_TO_MANY_FAILURE : "USER_SHARE_ACCESS_TO_MANY_FAILURE",


    GET_NEW_TOKEN_REQUEST: 'USERS_GET_NEW_TOKEN_REQUEST',
    GET_NEW_TOKEN_SUCCESS: 'USERS_GET_NEW_TOKEN_SUCCESS',
    GET_NEW_TOKEN_FAILURE: 'USERS_GET_NEW_TOKEN_FAILURE',

    

    LOGOUT : "USER_LOGOUT"
}

