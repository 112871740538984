import React,{ useState,useEffect } from 'react'
import './ResetPasswordRequestPage.css'
import { Form, Input, Button,Alert} from 'antd';
import { managerService, userService } from '../../_services';
import {history} from '../../_helpers'
import {connect} from 'react-redux'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { toast } from 'react-toastify';

const layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 20 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 20 },
};


function ResetPasswordRequestPage(props){


        const onFinish = values => {
          managerService.resetPassword({email:document.getElementById('email').value}) 
            .then(res => {
               toast('Nous avons envoyé un e-mail de vérification a votre boîte de réception')
              setTimeout(()=>{
                // window.location="http://localhost:3000/#/"
                 history.push('/')
              },3000)
            })
            .catch(err=> {
               toast('Spécifier une adresse mail valide')
            })
           
        };
   

        const onFinishFailed = errorInfo => {
        };
    return(
        // <div className="reset-password-request-page">
        //     <div className="reset-password-request-section">
        //         <h3>Réinitialisation du mot de passe</h3>
        //         <div style={{marginTop:'5%'}}>
        //         <p style={{marginLeft:'2%'}}>Veuillez entrer votre e-mail sur lequel vous recevrez le lien de réinitialisation du mot de passe</p>
        //         <Form   
        //               style={{width :"100%"}}
        //               {...layout}
        //               name="basic"
        //               initialValues={{ remember: true }}
        //               onFinish={onFinish}
        //               onFinishFailed={onFinishFailed}
        //             >
        //             {resetPasswordRequestSuccess? <Alert message="We've sent a password reset email to your adress" type="success"/>:null}
        //             {resetPasswordRequestError? <Alert message="make sure you entered a valid email" type="Error"/>:null}
        //             <Form.Item
        //                 name="email"
        //                 label="E-mail"
        //                 rules={[
        //                   {
        //                     type: 'email',
        //                     message: 'The input is not valid E-mail!',
        //                   },
        //                   {
        //                     required: true,
        //                     message: 'Please input your E-mail!',
        //                   },
        //                 ]}
        //                 >
        //                 <Input />
        //             </Form.Item>

        //               <Form.Item {...tailLayout}>
        //                 <Button type="primary" htmlType="submit">
        //                   Valider
        //                 </Button>
        //               </Form.Item>
        //         </Form>
        //         </div>
        //     </div>
        // </div>
        <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="5">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h2>Réinistialiser le mot de passe</h2>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="text" placeholder="email" autoComplete="email" id="email"/>
                    </CInputGroup>
                   
                    <CRow >
                      <CCol xs="4">
                        <CButton color="primary" onClick={onFinish} className="px-4 ">Verifier</CButton>
                      </CCol>
                      
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              {/* <CCard className="text-white bg-primary py-5 d-md-down-none" style={{ width: '44%' }}>
                <CCardBody className="text-center">
                  <div>
                    <h2>Sign up</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                      labore et dolore magna aliqua.</p>
                    <Link to="/register">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>Register Now!</CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard> */}
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
    )
}

const mapState = ( state ) => {
  const {authentication} = state
  return [
    authentication
  ]
}


const connectedResetPasswordRequestPage = connect(mapState)(ResetPasswordRequestPage)

export {connectedResetPasswordRequestPage as ResetPasswordRequestPage}