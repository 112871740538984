import axios from 'axios'
import {serverConstants} from '../_constants'

export const managerService = {
    getInsitutionUsers,
    getExperienceById,
    shareAccessToOne,
    createUser,
    uploadFile,
    shareAccessToMany,
    resetPassword,
    validateToken,
    updatePassword
}


function getInsitutionUsers(institution){
    return axios.get(`${serverConstants.DOMAIN_URL}/users/institution/${institution}`)
}

function getExperienceById(id){
    return axios.get(`${serverConstants.DOMAIN_URL}/experiences/${id}`)
}

function shareAccessToOne(req){
    return axios.put(`${serverConstants.DOMAIN_URL}/users/student/update/experiences`,req)
}

function shareAccessToMany(req){
    return axios.put(`${serverConstants.DOMAIN_URL}/users/students/update/experiences`,req)
}

function createUser(req){
    return axios.post(`${serverConstants.DOMAIN_URL}/users/add`,req)
}


function uploadFile(req){
    return axios.post(`${serverConstants.DOMAIN_URL}/users/add/students`,req
    )
}

function resetPassword(req){
    return axios.post(`${serverConstants.DOMAIN_URL}/users/reset`,req)
}


function validateToken(req){
    return axios.post(`${serverConstants.DOMAIN_URL}/users/token`,req)
}

function updatePassword(req){
    return axios.put(`${serverConstants.DOMAIN_URL}/users/update/password`,req)
}