import axios from 'axios'
import {serverConstants} from '../_constants'

export const authenticationService = {
    login,
    getNewToken
}


function login(req){
    return axios.post(`${serverConstants.DOMAIN_URL}/users/login`,req)
}
function getNewToken(req){
    return axios.post(`${serverConstants.DOMAIN_URL}/users/refresh`,req)
}